import { domain, clientId } from '../auth_config.json';

export const environment = {
  production: true,
  moralisAppId:"EezbSDXqq4jREgcT0TfMshHMvdf9v9TLsMXMc0zM",
  moralisUrl:"https://aufbfbal60uj.usemoralis.com:2053/server",
  endpoint:"https://roomzshow.xyz",
  //endpoint:"http://localhost:5000",
  token:"aioshdiohfaosihdoh1161aadhio465464ajdjpdsfads",
  //discordauth:"https://discord.com/api/oauth2/authorize",
  //discordClient:"948783641348161536",
  siteKey:"6LeWI7IeAAAAAFKUA8VrkIOuhV-8Qc0A2DhlymRf"
 
};
