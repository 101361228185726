import { Component, OnInit, OnDestroy, TemplateRef, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Moralis from "moralis";
import { Observable, Subject } from 'rxjs';
import { MainFacade } from 'src/app/services/main.facade';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-roomz',
  templateUrl: './roomz.component.html',
  styleUrls: ['./roomz.component.scss'],
  host:{'(document:scroll)':'scroll($event)'}
})
export class RoomzComponent implements OnInit, OnDestroy {
  style$: Observable<any>|undefined;
  private destroy$ = new Subject();
  blackBar:string='../../../assets/img/Roomz/Rectangle 3.png';
  yellowBar:string='../../../assets/img/Roomz/Rectangle 5.png';
  greyBar:string = '../../../assets/img/Roomz/Rectangle 4.png';
  display:number=0;
  selected:string;
  unselected:string;
  count:number=0;
  constructor(@Inject(DOCUMENT) document, private main:MainFacade) {

   }

  ngOnInit(): void {
    this.setSub();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }


  setSub(){
    this.style$ = this.main.style$;
    this.style$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        //this.setBars(message);
      }

    });
  }

  /*setBars(mode:string){
    if(mode==="SPACE"){
      this.selected = this.darkBar;
      this.unselected = this.lightBar;
    }
    else{
      this.selected = this.lightBar;
      this.unselected = this.greyBar;
    }
  }*/

  changeDisplay(pos){
    this.display = pos;
  }

  scroll(event){
    if(window.screen.width <= 700){
      return;
    }
    if(event.deltaY < 0){
      //down
      //this.scrollDown();
    }
    else {
      //this.scrollUp();
    }

    var reveals = document.querySelectorAll(".reveal");
    console.log(reveals)
    var revealP0 = document.getElementById("revealP0");
    var revealP1 = document.getElementById("revealP1");
    var revealP2 = document.getElementById("revealP2");
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 150;
          if (elementTop < windowHeight - elementVisible) {
            
            reveals[i].classList.add("opacity-100");
            reveals[i].classList.remove("opacity-0");
          } else {
           
            //reveals[i].classList.add("opacity-0");
            //reveals[i].classList.remove("opacity-100");
          }

          if(reveals[0].getBoundingClientRect().top > 570){
            revealP0.classList.add("opacity-100");
            revealP0.classList.remove("opacity-0");
            revealP1.classList.add("opacity-0");
            revealP1.classList.remove("opacity-100");
            revealP2.classList.add("opacity-0");
            revealP2.classList.remove("opacity-100");
          }
          else if(reveals[0].getBoundingClientRect().top < 570 &&  reveals[0].getBoundingClientRect().top > -100){
            revealP1.classList.add("opacity-100");
            revealP1.classList.remove("opacity-0");
            revealP0.classList.add("opacity-0");
            revealP0.classList.remove("opacity-100");
            revealP2.classList.add("opacity-0");
            revealP2.classList.remove("opacity-100");
          }
          else {
            revealP2.classList.add("opacity-100");
            revealP2.classList.remove("opacity-0");
            revealP1.classList.add("opacity-0");
            revealP1.classList.remove("opacity-100");
            revealP0.classList.add("opacity-0");
            revealP0.classList.remove("opacity-100");
          }    

          
        }
  }

  scrollUp(){
    if(this.count ===5){
      if(this.display !== 2){
       //this.display++;
      }
      this.count = 0;
    }
    this.count++;
  }

  scrollDown(){
    if(this.count ===5){
      if(this.display !== 0){
        //this.display--;
      }
      this.count = 0;
    }
    this.count++;
  }

}
