import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./pages/main/main.component";
import { AuthGuard } from "./auth.guard";
import { RoomzComponent } from "./pages/roomz/roomz.component";
import { BuilderComponent } from "./pages/builder/builder.component";
import { MapComponent } from "./pages/map/map.component";
import { GalleryComponent } from "./pages/gallery/gallery.component";
import { TeamComponent } from "./pages/team/team.component";
import { MintComponent } from "./pages/mint/mint.component";


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: MainComponent },
  { path: "roomz", component: RoomzComponent },
  { path: "map", component: MapComponent },
  { path: "builder", component: BuilderComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "team", component: TeamComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule { }

/*
,
  { path: "mint", component: MintComponent }*/
