// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { domain, clientId } from '../auth_config_dev.json';

export const environment = {
  production: false,
  moralisAppId:"tHmwuSMAbzPWu4X4aYO4VWSfhgDdLlr6LWj6X6yj",
  moralisUrl:"https://sxstjm2ow8nw.usemoralis.com:2053/server",
  endpoint:"http://localhost:5000",
  token:"aioshdiohfaosihdoh1161aadhio465464ajdjpdsfads",
  roomzContract:"",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

