import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainFacade } from 'src/app/services/main.facade';

@Component({
  selector: 'app-mint',
  templateUrl: './mint.component.html',
  styleUrls: ['./mint.component.scss']
})
export class MintComponent implements OnInit, OnDestroy {
  signature:any;
  signature$: Observable<any> | undefined;
  login$: Observable<any> | undefined;
  mint$: Observable<any> | undefined;
  terms=false;
  quantity:number=1;
  tweetUrl='https://twitter.com/intent/tweet?title=I just minted my @roomztoshow Neo Tokyo exclusive banner!&text=Can´t wait to try out the builder https://www.roomztoshow.com/#/builder and personalize my twitter banner! ';
  private destroy$ = new Subject();
  constructor(private mainFacade: MainFacade) { }

  ngOnInit(): void {
    this.createSubs();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  createSubs(){
    this.signature$=this.mainFacade.signature$;
    this.login$=this.mainFacade.login$;
    this.mint$=this.mainFacade.mint$;
    this.signature$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        //this.drawCanvas();
        this.signature = message;
      }
      

    });
  }

  quantityChange(state){
    if(state==='add' && this.quantity <1){
      this.quantity++;
    }
    else if(state==='minus' && this.quantity > 0) {
      this.quantity--;
    }
  }

  login(){
    this.mainFacade.login(1);
  }

  mint(){
    this.mainFacade.mint(this.signature, this.quantity);
  }


}
