import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RoomzFeatureKey } from './r2s.reducer';

const getRoomzFeatureKey = createFeatureSelector<any>(RoomzFeatureKey);

export const getError = createSelector(
    getRoomzFeatureKey,
(state: any) => {
  return state.error
}
);

export const login = createSelector(
    getRoomzFeatureKey,
(state: any) => {
  return state.login
}
);

export const getNfts = createSelector(
    getRoomzFeatureKey,
(state: any) => {
  return state.nfts
}
);

export const getStyle = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.style
}
);

export const getAssets = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.assets
}
);

export const getDimensions = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.dimensions
}
);

export const getMoreNfts = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.moreNfts
}
);

export const getRemainingNfts = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.remaining
}
);

export const getNftAssets = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.nftAssets
}
);

export const getMint = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.mint
}
);

export const getSignature = createSelector(
  getRoomzFeatureKey,
(state: any) => {
return state.signature
}
);


export const RoomzQuery = {
    getRoomzFeatureKey,
    getError,
    login,
    getNfts,
    getStyle,
    getAssets,
    getDimensions,
    getMoreNfts,
    getRemainingNfts,
    getNftAssets,
    getMint,
    getSignature
};