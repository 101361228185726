import { Action, createReducer } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import * as RoomzActions from './r2s.action';
import * as moment from 'moment';
export const RoomzFeatureKey = 'r2s';

export interface RoomzState {
  readonly [RoomzFeatureKey]: any;
}

export const roomzInitialState: any = {
  error: '',
  login:'',
  nfts:[],
  style:'YACHT',
  assets:'',
  dimensions:'',
  moreNfts:0,
  remaining:0,
  nftAssets:[],
  signature:'',
  mint:''
};

const reducer = createReducer(
    roomzInitialState,

  immerOn(RoomzActions.getLoginSuccess, (state:any, action:any) => {
    state.login = action.login;
    state.error='';
  }),

  immerOn(RoomzActions.getLoginFail, (state:any, action:any) => {
    state.login = '';
    state.error=action.error;
  }),

  immerOn(RoomzActions.getLogOutSuccess, (state:any, action:any) => {
    state.login = '';
    state.nfts =[];
    state.error='';
  }),

  immerOn(RoomzActions.getLogOutFail, (state:any, action:any) => {
    //state.login = '';
    state.error=action.error;
  }),

  immerOn(RoomzActions.getNftsSuccess, (state:any, action:any) => {
    state.nfts = action.Nfts;
    state.moreNfts = action.moreNfts;
    state.remaining = action.remaining;
    state.error='';
  }),

  immerOn(RoomzActions.getNftsFail, (state:any, action:any) => {
    state.nfts =[];
    state.moreNfts = 0;
    state.remaining = 0;
    state.error=action.error;
  }),

  immerOn(RoomzActions.getNftsByContractSuccess, (state:any, action:any) => {
    state.nfts = action.Nfts;
    state.moreNfts = action.moreNfts;
    state.remaining = action.remaining;
    state.error='';
  }),

  immerOn(RoomzActions.getNftsByContractFail, (state:any, action:any) => {
    state.nfts =[];
    state.moreNfts = 0;
    state.remaining = 0;
    state.error=action.error;
  }),

  immerOn(RoomzActions.getDimensionsSuccess, (state:any, action:any) => {
    state.dimensions = action.dimensions;
    state.error='';
  }),

  immerOn(RoomzActions.getDimensionsFail, (state:any, action:any) => {
    state.dimensions ='';
    state.error=action.error;
  }),

  immerOn(RoomzActions.getOwnerAssetsSuccess, (state:any, action:any) => {
    state.nftAssets = action.assets;
    //state.dimensions = action.dimensions;
    state.error='';
  }),

  immerOn(RoomzActions.getOwnerAssetsFail, (state:any, action:any) => {
    state.nftAssets =[];
    state.error=action.error;
  }),

  immerOn(RoomzActions.changeStyleSuccess, (state:any, action:any) => {
    if(action.style.style){
      state.style=action.style.style;
    }
    state.assets=action.style.assets;
    
  }),

  immerOn(RoomzActions.mintFail, (state:any, action:any) => {
    state.mint='';
    state.error=action.error;
  }),

  immerOn(RoomzActions.mintSuccess, (state:any, action:any) => {
    
    state.mint=action.mint;
    
  }),

  immerOn(RoomzActions.getSignatureFail, (state:any, action:any) => {
    state.signature='';
    state.error=action.error;
  }),

  immerOn(RoomzActions.getSignatureSuccess, (state:any, action:any) => {
    
    state.signature=action.signature;
    
  }),
);

export function roomzReducer(state: any, action: Action): any {
    return reducer(state, action);
}