import { Injectable, Inject } from '@angular/core';
//import { environment } from 'src/environments/environment';
import { environment } from 'src/environments/environment.prod';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as bcrypt from 'bcryptjs';

import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
const Web3 = require('web3');
//let web3 = new Web3('ws://localhost:8546');
@Injectable({
  providedIn: 'root'
})
export class ApiService {

readonly rootURL = environment.endpoint;

//readonly rootURL = "http://localhost:5000";
constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient) {}

getHeaders(){
  return {
    headers: new HttpHeaders({
      'Authorization': environment.token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
      "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
      "Sig":localStorage.getItem('sig')
    })
  };
}

logError(error, id, currentState){
  const httpOptions = this.getHeaders();
  let body = {
    Error:error,
    Id:id,
    State:currentState
  }
  return this.http.post(this.rootURL+"/api/Log/Error", body, httpOptions);
}

getDimensions(names:string[]){
  const httpOptions = this.getHeaders();
  let body = {
    Names:names
  }
  return this.http.post(this.rootURL+"/api/Dimensions", body, httpOptions);
}

getAssets(wallet:string){
  const httpOptions = this.getHeaders();
  let body = {
    wallet:wallet
  }
  return this.http.post(this.rootURL+"/api/Dimensions/owner", body, httpOptions);
}

getProof(wallet:string){
  const httpOptions = this.getHeaders();
  let body = {
    wallet:wallet
  }
  return this.http.post(this.rootURL+"/api/Dimensions/proof", body, httpOptions);
}

getSignature(wallet:string, id:number){
  const httpOptions = this.getHeaders();
  let body = {
    wallet:wallet,
    id:id
  }
  return this.http.post(this.rootURL+"/api/Dimensions/sig", body, httpOptions);
}



}
