export class RoomzStore {
    style:string;
    constructor() { }

    setStyle(style){
        this.style = style;
    }

    getBG() {
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/BG/BG2.png',
                '../../assets/img/main/mainrotate/yacht/BG/BG3.png',
                '../../assets/img/main/mainrotate/yacht/BG/BG5.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/space/BG/earth.png',
                '../../assets/img/main/mainrotate/space/BG/spaceships.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getLocation() {
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/yacht/A1.png',
                '../../assets/img/main/mainrotate/yacht/yacht/A2.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/space/ship/B1.png',
                '../../assets/img/main/mainrotate/space/ship/B4.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getTable(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/table/black circular table.png',
                '../../assets/img/main/mainrotate/yacht/table/frosted table.png',
                '../../assets/img/main/mainrotate/yacht/table/metal box.png',
                '../../assets/img/main/mainrotate/yacht/table/grey circular table.png',
                '../../assets/img/main/mainrotate/yacht/table/wooden desk.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/table/black circular table.png',
                '../../assets/img/main/mainrotate/yacht/table/metal box.png',
                '../../assets/img/main/mainrotate/space/table/scifi table 1.png',
                '../../assets/img/main/mainrotate/yacht/table/grey circular table.png',
                '../../assets/img/main/mainrotate/yacht/table/wooden desk.png',

            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getDisplay(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/display/black frame.png',
                '../../assets/img/main/mainrotate/yacht/display/easel.png',
                '../../assets/img/main/mainrotate/yacht/display/record player.png',
                '../../assets/img/main/mainrotate/yacht/display/white frame.png',
                '../../assets/img/main/mainrotate/yacht/display/white tablet.png',
                
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/display/black frame.png',
                '../../assets/img/main/mainrotate/yacht/display/easel.png',
                '../../assets/img/main/mainrotate/yacht/display/record player.png',
                '../../assets/img/main/mainrotate/yacht/display/white frame.png',
                '../../assets/img/main/mainrotate/space/display/scifi screen 1.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getLeft(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/left/glasses.png',
                '../../assets/img/main/mainrotate/yacht/left/gun.png',
                '../../assets/img/main/mainrotate/yacht/left/smallvase.png',
                '../../assets/img/main/mainrotate/yacht/left/wineglass.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/left/glasses.png',
                '../../assets/img/main/mainrotate/yacht/left/gun.png',
                '../../assets/img/main/mainrotate/yacht/left/smallvase.png',
                '../../assets/img/main/mainrotate/yacht/left/wineglass.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getRight(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/right/banknotes.png',
                '../../assets/img/main/mainrotate/yacht/right/glass.png',
                '../../assets/img/main/mainrotate/yacht/right/headphones.png',
                '../../assets/img/main/mainrotate/yacht/right/winebottlglass.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/right/banknotes.png',
                '../../assets/img/main/mainrotate/yacht/right/glass.png',
                '../../assets/img/main/mainrotate/yacht/right/headphones.png',
                '../../assets/img/main/mainrotate/yacht/right/winebottlglass.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getLounge(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/middle/bean bags.png',
                '../../assets/img/main/mainrotate/yacht/middle/carpet.png',
                '../../assets/img/main/mainrotate/yacht/middle/chair.png',
                '../../assets/img/main/mainrotate/yacht/middle/hot tube.png',
                '../../assets/img/main/mainrotate/yacht/middle/sunchair.png',
                
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/middle/bean bags.png',
                '../../assets/img/main/mainrotate/yacht/middle/carpet.png',
                '../../assets/img/main/mainrotate/yacht/middle/chair.png',
                '../../assets/img/main/mainrotate/yacht/middle/hot tube.png',
                '../../assets/img/main/mainrotate/yacht/middle/sunchair.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }

    getFrames(){
        if(this.style === "YACHT"){
            return [
                '../../assets/img/main/mainrotate/yacht/frames/5frames.png',
                '../../assets/img/main/mainrotate/yacht/frames/canvas.png',
            ];
        }
        else if(this.style === "SPACE"){
            return [
                '../../assets/img/main/mainrotate/yacht/frames/5frames.png',
                '../../assets/img/main/mainrotate/yacht/frames/canvas.png',
            ];
        }
        else if(this.style === "CABIN"){
            return [

            ];
        }
    }
}