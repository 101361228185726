import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { SubjectSubscriber } from 'rxjs/internal/Subject';
import { Observable, Subject } from 'rxjs';
import { MainFacade } from 'src/app/services/main.facade';
import { takeUntil } from 'rxjs/operators';
import { DragulaService } from 'ng2-dragula';
import Moralis from 'moralis';
import { Web3Service } from 'src/app/services/web3.services';
import * as Perspective from 'perspectivejs';
//import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from 'angular-toastify';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-builder',
  templateUrl: './builder.component.html',
  styleUrls: ['./builder.component.scss']
})
export class BuilderComponent implements OnInit, AfterViewInit,OnDestroy {
  nfts$: Observable<any> | undefined;
  dimensions$: Observable<any> | undefined;
  login$: Observable<any> | undefined;
  moreNfts$: Observable<any> | undefined;
  remaining$: Observable<any> | undefined;
  nftRoomz$: Observable<any> | undefined;
  private destroy$ = new Subject();
  list: string = '';
  cdn:string = 'https://roomzcdn.nyc3.digitaloceanspaces.com';
  nftSamples:any[] = [
    [{place: 11, src:0},{place:0,src:'YachtBG-5'}, {place:1,src:'YachtB5'},{place:2,src:'NFT2-canvas'}, {place:3,src:''}, {place:4,src:'mg-element-chair'}, {place:5,src:'Tables-black-circular-table'}, {place:6,src:'fg-element-left-banknotes'}, {place:7,src:'fg-element-right-watch'}, {place:8,src: 'NFT1-BG-Rectangle'}, {place:9,src:'black-frame-watermark'}, {place:10, src:'https://roomzcdn.nyc3.digitaloceanspaces.com/yacht_demo.png'}],
    [{place: 11, src:1},{place:0,src:'spaceshipB-BG-eart'}, {place:1,src:'SpaceshipB1'},  {place:2,src:'NFT2-5framescrooked02'}, {place:3,src:'NFT2-5framescrooked01'}, {place:4,src:'mg-element-bean-bags'}, {place:5,src:'Tables-white'}, {place:6,src:'fg-element-left-headphones'}, {place:7,src:'fg-element-right-glasses'}, {place:8,src: 'NFT1-BG-Rectangle'}, {place:9,src:'scifi-watermark'}, {place:10, src:'https://roomzcdn.nyc3.digitaloceanspaces.com/space_demo.png'},]
  ];
  searchInp:string="";
  assetsById:string[] = [];
  nftSelect: any[] = [];
  position: any[] = [];
  collectionList: any[] =[];
  selectedCollection:any='';
  selectedToken:number = 0;
  pagination:number = 20;
  currentNFTSelected: any = null;
  canvasLoaded:boolean = false;
  currentNfts:any[] = [];
  loadingMore:boolean = false;
  toggle:boolean = false;
  imageChangedEvent: any = '';
  imageURL: any = '';
  croppedImage: any = '';
  srcList:any=[];
  constructor(private mainFacade: MainFacade, private sanitizer: DomSanitizer, private web3: Web3Service, private toastr:ToastService) {

  }

  ngOnInit(): void {
    //get assets

    //getNfts
    this.setSubs();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  displayError(id){
    let doc = document.getElementById(id);
    doc.classList.add('hidden');
    let docErr = document.getElementById('error_'+id);
    docErr.classList.remove('opacity-0');
  }

  safeSVGRet(svgContent:any, image){

    let sn;
    if(image){
      sn=this.sanitizer.bypassSecurityTrustUrl(svgContent);
    }
    else{
      sn = this.sanitizer.bypassSecurityTrustHtml(svgContent);
    }
  
    return sn
  }

  createNamesList(){
    let names = [];
    this.nftSamples[this.selectedToken].forEach((asset)=>{
      if(asset.place !== 11 &&  asset.place !== 10){
        names.push(asset.src);
      }
    });

    this.mainFacade.getDimensions(names);
  }

  setSubs() {
    this.nfts$ = this.mainFacade.nfts$;
    this.dimensions$ = this.mainFacade.dimensions$;
    this.login$ = this.mainFacade.login$;
    this.moreNfts$ = this.mainFacade.moreNfts$;
    this.remaining$ = this.mainFacade.remaining$;
    this.nftRoomz$ = this.mainFacade.nftAssets$;
    //This will be moved when we use the full nft logic
    this.createNamesList();
    this.nfts$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        //this.drawCanvas();
        this.currentNfts = message;
        this.loadingMore = false;
      }
      

    });

    this.nftRoomz$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message.length > 0){
        //this.drawCanvas();
        
        this.nftSamples = message;
       
      }
      

    });

    this.dimensions$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.position = [];
        
        let index =this.nftSelect.findIndex(x=>{
          return message.dimensions.length < x.place;
        })
        
        if(index > -1){
          this.nftSelect.splice(index, 1);
          
        }
    
        message.dimensions.forEach(element => {
          Object.freeze(element);
          this.position.push({...element});
        });
    
        this.drawCanvas();
      }

    });
  }

  ngAfterViewInit(): void {
    //this.drawCanvas();
  }

  dragStart(event, nft) {
    this.currentNFTSelected = nft;

  }
  
  onDrop(event, place) {
    event.preventDefault();
    event.stopPropagation();
    // your code goes here after droping files or any
    // populate position with current dragged nft
    if (this.currentNFTSelected) {
      this.imageURL = this.currentNFTSelected.img; 
      this.imageChangedEvent = this.currentNFTSelected.img;
      //this.fileChangeEvent(this.currentNFTSelected.img);
      //console.log(this.currentNFTSelected.img)
      this.setImage(place, this.currentNFTSelected.img)
    }
  }

  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  setImage(place, nft) {
    let indx = this.nftSelect.findIndex((x) => { return x.place === place });

    if (indx >= 0) {
      this.nftSelect.splice(indx, 1);
    }

    this.nftSelect.push({ place: place, nft: nft });

    this.drawCanvas();
  }

  getImage(){
    for(let x = 0; this.position.length > x;x++){
      let positionFound = this.nftSelect.find(y=>{return y.place === this.position[x].place });
      if(positionFound){
   
        this.position[x].img = positionFound.nft;
      }
    }

    
  }

  drawCanvas() {
    this.canvasLoaded = false;
    var ctx = (<HTMLCanvasElement>document.getElementById('canvasLG')).getContext('2d');
    //add bg,loc,rect
    //get assets for 
    this.getImage();
    let selectNFT = this.nftSamples.find((x,indx)=>{
      
      return x[0].place === 11 && x[0].src === this.selectedToken
    });
    let pos = 0;
    var layers = [];
    while(pos < 3){
 
      let token = selectNFT.find((x)=>{return x.place === pos});
      if(token.src){
        layers.push({ src:this.cdn+'/'+token.src+'.png', place: 0});
      }
      //layers.push({ src:this.cdn+'/'+token.src+'.png', place: 0});
      pos++;
    }

    this.nftSelect.forEach(element => {
      //add in display
      if (element.place !== 1) {
        layers.push({ src: element.nft, place: element.place })
      }
    });

    pos = 3;
    while(pos < 9){
      let token = selectNFT.find((x)=>{return x.place === pos});
      if(token.src){
        layers.push({ src:this.cdn+'/'+token.src+'.png', place: 0});
      }
      pos++
    }

    this.nftSelect.forEach(element => {
      if (element.place === 1) {
        layers.push({ src: element.nft, place: element.place })
      }
    });
    var token8 = selectNFT.find((x)=>{return x.place === 9});
    layers.push({ src:this.cdn+'/'+token8.src+'.png', place: 0});

    Promise
      .all(layers.map(i => this.drawNftIn(i.place, i.src)))
      .then((newImages) => {
        console.log(newImages)
        for(var y = 0; newImages.length > y;y++){

          var place = newImages[y]['place'];
          
          var src = newImages[y]['src'];
          var newImage = newImages[y]['image'];
          if (place > 0 && this.position.length > 0) {
            var positionData = this.position.find((x) => {
              return x.place === place;
            })
            //dynamically look up canvas data
              let newCanvas = this.drawImageInPerspective(newImage, ctx,
              positionData.topLeftX, positionData.topLeftY,
              positionData.bottomLeftX, positionData.bottomLeftY,
              positionData.topRightX, positionData.topRightY,
              positionData.bottomRightX, positionData.bottomRightY);
                var p = new Perspective(ctx, newCanvas);
                p.draw([
                    [positionData.topLeftX, positionData.topLeftY],                               // Top-left [x, y]
                    [positionData.topRightX, positionData.topRightY],                 // Top-right [x, y]
                    [positionData.bottomRightX, positionData.bottomRightY],  // bottom-right [x, y]
                    [positionData.bottomLeftX, positionData.bottomLeftY]                      // bottom-left [x, y]
                ]);
         

          }
          else if(newImage) {

            ctx.drawImage(newImage, 0, 0, 6000, 2000);
          }
        }
        
      }).catch(error=>{

      }).finally(()=>{

        var canvas = (<HTMLCanvasElement>document.getElementById('canvas'));
        var ctx = canvas.getContext('2d');
        var canvasTw = (<HTMLCanvasElement>document.getElementById('canvasTw'));
        var ctxTw = canvasTw.getContext('2d');
        var ctxLg = (<HTMLCanvasElement>document.getElementById('canvasLG'));
        let height;
        let width;
        if(window.innerWidth < 1700){
          width = 1200;
          height = 400;
          //Twitter Size
          canvasTw.width = 1500;
          canvasTw.height = 500;
          var tmpCanvasTw = document.createElement('canvas');
          tmpCanvasTw.width = 1500;
          tmpCanvasTw.height = 500;
          var tmpContextTw = tmpCanvasTw.getContext('2d');
          this.downSize(tmpCanvasTw, tmpContextTw, ctxLg, 6000, 2000)
          ctxTw.drawImage(tmpCanvasTw, 0, 0, 1500, 500);
        }
        else{
          width = 1500;
          height = 500;
        }
        

        //screen size
        canvas.width =width;
        canvas.height = height;
        var tmpCanvas = document.createElement('canvas');
        tmpCanvas.width = width;
        tmpCanvas.height = height;
 
        var tmpContext = tmpCanvas.getContext('2d');
        this.downSize(tmpCanvas, tmpContext, ctxLg, 6000, 2000)
        ctx.drawImage(tmpCanvas, 0, 0, width, height);

        this.canvasLoaded = true;

      })
  }



  async drawNftIn(position, src) {
    var newImage = new Image();
    newImage.crossOrigin = "anonymous";
    if(src.indexOf('digitalocean')>-1 && src.indexOf('neo') < 0){

      newImage.src=src;
    }
    else{
      //let strg = 'https://utqa73v9yf.execute-api.us-east-1.amazonaws.com/dev/cors-proxy?url='+src;
      //let img = await this.convertImage(strg);
      //console.log(img);
     // newImage.src = img.toString();
     let found=this.srcList.find((val)=>{return val.src === src});
     if(!found){
      console.log(src)
      let data= await fetch(environment.endpoint+'/api/image/'+encodeURIComponent(src));     
      let base = await data.text();
      this.srcList.push({src:src, base:'data:image/png;base64,'+base});
      newImage.src = 'data:image/png;base64,'+base;
     }
     else{
      newImage.src = found.base;
     }
    }
    console.log(newImage.src)
      //newImage.src = src+"?not-from-cache-please";
    return new Promise((resolve, reject) => {
      newImage.onload = () => {
        resolve({
          image: newImage, place: position, src:src
        });
      }
      newImage.onerror = (error) =>{
        this.toastr.error('Image could not be loaded into the canvas.');
        console.log(error)
        resolve({
          image: '', place: 0, src:''
        });
      }
      
    });

  }

  resizeImg(img) {
    var width = img.width;
    var height = img.height;
    /*if(tmpWidth !== tmpHeight){
      tmpCanvas.height = tmpHeight > tmpWidth ? (tmpHeight - (tmpHeight - tmpWidth)) : tmpHeight;
    }
    else{
      tmpCanvas.height = tmpHeight;
    }
    var maxWidth = 80;
    var maxHeight = 90;
    var width = img.width;
    var height = img.height;
    var aspectW = width / maxWidth;
    var aspectH = height / maxHeight;

    if (aspectW > 1 || aspectH > 1) {
      if (aspectW > aspectH) {
        img.width = maxWidth;
        img.height = height / aspectW;
      }
      else {
        img.height = maxHeight;
        img.width = width / aspectH;
      }
    }*/
    return [img.width, img.height]
  }

  drawImageInPerspective(
    srcImg,
    targetCanvas,
    topLeftX, topLeftY,
    bottomLeftX, bottomLeftY,
    topRightX, topRightY,
    bottomRightX, bottomRightY,

  ) {
    var targetMarginX = Math.min(topLeftX, bottomLeftX, topRightX, bottomRightX);
    var targetMarginY = Math.min(topLeftY, bottomLeftY, topRightY, bottomRightY);

    var targetTopWidth = (topRightX - topLeftX);
    var targetTopOffset = topLeftX - targetMarginX;
    var targetBottomWidth = (bottomRightX - bottomLeftX);
    var targetBottomOffset = bottomLeftX - targetMarginX;

    var targetLeftHeight = (bottomLeftY - topLeftY);
    var targetLeftOffset = topLeftY - targetMarginY;
    var targetRightHeight = (bottomRightY - topRightY);
    var targetRightOffset = topRightY - targetMarginY;

    var tmpWidth = Math.max(targetTopWidth + targetTopOffset, targetBottomWidth + targetBottomOffset);
    var tmpHeight = Math.max(targetLeftHeight + targetLeftOffset, targetRightHeight + targetRightOffset);
    var tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = tmpWidth;
    tmpCanvas.height = tmpHeight;
    var tmpContext = tmpCanvas.getContext('2d');
    this.downSize(tmpCanvas, tmpContext, srcImg, tmpWidth, tmpHeight)
    return tmpCanvas;
    //let array = this.resizeImg(srcImg);
    //tmpContext.drawImage(srcImg, 0, 0, tmpWidth, tmpHeight);
    //tmpContext.drawImage(srcImg, 0, 0,srcWidth, srcHeight);
    /*var tmpMap = tmpContext.getImageData(0, 0, tmpWidth, tmpHeight);
    var tmpImgData = tmpMap.data;
    var targetContext = targetCanvas;//.getContext('2d');
    var targetMap = targetContext.getImageData(targetMarginX, targetMarginY, tmpWidth, tmpHeight);
    var targetImgData = targetMap.data;
    console.log(targetImgData)
    var tmpX, tmpY,
      targetX, targetY,
      tmpPoint, targetPoint;

    for (tmpY = 0; tmpY < tmpHeight; tmpY++) {
      for (tmpX = 0; tmpX < tmpWidth; tmpX++) {

        //Index in the context.getImageData(...).data array.
        //This array is a one-dimensional array which reserves 4 values for each pixel [red,green,blue,alpha) stores all points in a single dimension, pixel after pixel, row after row:
        tmpPoint = (tmpY * tmpWidth + tmpX) * 4;

        //calculate the coordinates of the point on the skewed image.
        //
        //Take the X coordinate of the original point and translate it onto target (skewed) coordinate:
        //Calculate how big a % of srcWidth (unskewed x) tmpX is, then get the average this % of (skewed) targetTopWidth and targetBottomWidth, weighting the two using the point's Y coordinate, and taking the skewed offset into consideration (how far topLeft and bottomLeft of the transformation trapezium are from 0).   
        targetX = (
          targetTopOffset
          + targetTopWidth * tmpX / tmpWidth
        )
          * (1 - tmpY / tmpHeight)
          + (
            targetBottomOffset
            + targetBottomWidth * tmpX / tmpWidth
          )
          * (tmpY / tmpHeight)
          ;
        targetX = Math.round(targetX);

        //Take the Y coordinate of the original point and translate it onto target (skewed) coordinate:
        targetY = (
          targetLeftOffset
          + targetLeftHeight * tmpY / tmpHeight
        )
          * (1 - tmpX / tmpWidth)
          + (
            targetRightOffset
            + targetRightHeight * tmpY / tmpHeight
          )
          * (tmpX / tmpWidth)
          ;
        targetY = Math.round(targetY);

        targetPoint = (targetY * tmpWidth + targetX) * 4;

        targetImgData[targetPoint] = tmpImgData[tmpPoint];  //red
        targetImgData[targetPoint + 1] = tmpImgData[tmpPoint + 1]; //green
        targetImgData[targetPoint + 2] = tmpImgData[tmpPoint + 2]; //blue
        targetImgData[targetPoint + 3] = tmpImgData[tmpPoint + 3]; //alpha
      }
    }
    
    targetContext.putImageData(targetMap, targetMarginX, targetMarginY);*/
  }

  downSize(canvas, ctx, img, width, height){
       var oc = document.createElement('canvas'),
       octx = oc.getContext('2d');

    var cur = {
      width: Math.floor(img.width * 0.5),
      height: Math.floor(img.height * 0.5)
    }

    oc.width = cur.width;
    oc.height = cur.height;

    octx.drawImage(img, 0, 0, cur.width, cur.height);

    while (cur.width * 0.5 > width) {
      cur = {
       width: Math.floor(cur.width * 0.5),
       height: Math.floor(cur.height * 0.5)
      };
      octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
    }

    ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);

  }

  changeSelect(pos){
    this.selectedToken = pos;
    this.createNamesList();
  }

  exportTwitter(){
    var download = (<HTMLLinkElement>document.getElementById("download"));
    var ctx;
    if(window.innerWidth < 1700){
      ctx = (<HTMLCanvasElement>document.getElementById('canvasTw'))
    }
    else {
      ctx = (<HTMLCanvasElement>document.getElementById('canvas'))
    }

    var image = ctx.toDataURL('image/png').replace("image/png", "image/octet-stream");
    download.crossOrigin = "anonymous";
    download.setAttribute("href", image);


  }


  exportLG(){
    var download = (<HTMLLinkElement>document.getElementById("downloadLG"));
    var ctx = (<HTMLCanvasElement>document.getElementById("canvasLG"));
    var image = ctx.toDataURL('image/png').replace("image/png", "image/octet-stream");
    download.crossOrigin = "anonymous";
    download.setAttribute("href", image);
    
    

  
  }


  findCollection(event){
    if(!this.searchInp || this.searchInp.length < 3){
      this.collectionList =[];
      
      return;
    }
    //search nft collection list
    this.collectionList = this.web3.searchCollections(this.searchInp);
  }

  updateNfts(collection:any){
    //save collection
    this.selectedCollection = collection;
    this.collectionList = [];
    this.pagination = 20;
    //load nfts
    this.mainFacade.loadNftsByContract(collection.token_address, this.pagination, this.currentNfts);
  }

  loadMore(){
    this.loadingMore = true;
    this.pagination+=20;
    //load nfts
    this.mainFacade.loadNftsByContract(this.selectedCollection?.token_address, this.pagination, this.currentNfts)
  }

  clearNftFilter(){
    this.pagination = 0;
    this.collectionList =[];
    this.searchInp = '';
    this.selectedCollection='';
    this.mainFacade.loadNftsByContract('', 0, []);
  }
 
}
