import { APP_INITIALIZER, Component, OnInit } from '@angular/core';
import { time } from 'console';
import { Certificate } from 'crypto';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  host: {'(window:resize)': 'resize($event)'}
})
export class TeamComponent implements OnInit {
  dimensions:any[] = [
    {pos:'pos1', x: 1064, y:160},
    {pos:'pos2', x: 1165, y:148},
    {pos:'pos3', x: 1271, y:139},
    {pos:'pos4', x: 1063, y:275},
    {pos:'pos5', x: 1163, y:270},
    {pos:'pos6', x: 1269, y:269}
  ]
  smdimensions:any[] = [
    {pos:'pos1', x: 216, y:150},
    {pos:'pos2', x: 318, y:145},
    {pos:'pos3', x: 420, y:140},
    {pos:'pos4', x: 217, y:250},
    {pos:'pos5', x: 319, y:250},
    {pos:'pos6', x: 421, y:250}
  ]
  teamNameText: string = '';
  teamTitleText: string = '';
  teamBodyText: string = '';
  teamTextArr:any[] = [
    {name:'Meet Our Team', title:'Roomz To Show Team',  body:'Meet the Crew that makes in all happen. Click the profiles on the wall to get more details about each of our team members'},
    {name:'White Oak', title:'Full Stack Solidty Dev',  body:"I enjoy long walks on the beach, getting endless discord notifications, and writing in Solidity.Before starting my career as a Solidity dev in early 2021, I built and ran mathematical models to predict chemical exposure risk (aka I'm having more fun with JPEGs). I sometimes dream about gas optimization. Is this normal?"},
    {name:'Teglas13', title:'Founder / Web3 allrounder',  body:"Starting in NFTs early 2021 and more intense in Summer 2021 I quickly wanted to create my own project that actually solves a problem that I saw in the market. Leading the Welcome Party in Neo Tokyo and being deeply involved in many areas of the citadel finally made it possible to take the leap of faith, start my own project and work full time in web3. I enjoy connecting people and organizing on a high level. In the end, it's all about connections in your life."},
    {name:'Lucifen', title:'Co-founder / community building',  body:"0n1-force was my entry in the web3 world. I was drawn by the vision of what it could turn into, the art and the community. Shortly after I became active in Mars Cats Voyage, another project I saw potential in. From an early user that created welcome memes I turned into mod, then head-mod. It was a great time where I could exchange my own ideas with the team and help come up with a solution to successfully mint out and get the project going and make the SST feature a little more exciting.I’m happy I can once again help come up with creative solutions, explore the web3 potential and work with amazing and talented people - this time as part of the team!"},
    {name:'Mr. AA', title:'Community building / full time degen',  body:"During my Birthday month September 2021 I joined the craze of the NFT market. I quickly got involved in Mars Cats Voyage as a moderator. Áfter that I became a full time 24/7 degen and alpha hunter. Networking is one of my objectives in web3. Let's build because I am buildoor!"},
    {name:'SimplyChaotic', title:'Full Stack Dev',  body:"A daily multi-cup coffee chugger and anime addict, I unintentional fell into the NFTs space when a friend of a friend asked me to be a founder on an up and coming Web3 game. Six months later, I have YOLO'd all conventional logic, leaving behind a cush Fortune 500 job to join the degen cult. Since then I have provided development work on projects like Dysto Apez, Godjira, Anime Meta, R Planet, Yin Yang Gang, Aevos and am a founder on projects like The Scrapyard, Dank Cellar, and JiraXchange. It was a pleasure to get to build the R2S dapp and look forward to future improvements."},
    {name:'Dennis Pen Name', title:'Artist',  body:"Formally working as an Illustrator for numerous productions in the animation industry, I now work any thing from character to vehicle design. Recently, I have primarily put my focus in environment and set design for 2D and 3D shows for Netflix and Amazon Prime. My portfolio covers a wide variety of styles aimed at adults, Kindergarten children and everything in between."},
    
  ]
  constructor() { }

  ngOnInit(): void {
    document.addEventListener('click',(src)=>{
      this.changeText(0);
    })
    this.teamNameText = this.teamTextArr[0].name;
    this.teamTitleText = this.teamTextArr[0].title;
    this.teamBodyText = this.teamTextArr[0].body;
    this.createClickDivs();
  }

  resize(event){
    this.createClickDivs();

  }

  createClickDivs(){
    let dim = this.dimensions;
    if(window.screen.width <= 760){
      dim = this.smdimensions;
    }

    dim.forEach(element => {
      let pos = this.getCalcPos(element.x,element.y);
      var div = document.getElementById(element.pos);
      div.style.top = pos.top+"px";
      div.style.left = pos.left+"px";
      div.style.height = pos.height+"px";
      div.style.lineHeight = pos.height+"px";
      div.style.width = pos.width+"px";
    });
    
    
  }

  getCalcPos(x:number, y:number){
    var fullWidth = 1500;
    var imgWidth = 70;
    var fullHeight = 600;
    var imgHeight = 100;
    var pointX = x;
    var pointY = y;
    var img = document.getElementById('teamFull');
    if(window.screen.width <= 760){
      img = document.getElementById('teamFullSm');
      fullWidth = 650;
      imgWidth = 70;
      fullHeight = 500;
      imgHeight = 70;
    }
    //or however you get a handle to the IMG
    var width = img.clientWidth;
    var height = width*(3.33/10);

    var diff = (window.innerHeight - height)/2;
    if(window.screen.width <= 760){
      diff=0;
      height = width*0.75;
    }

    var heightCalc = ((pointY * height)/fullHeight)+diff;
    var top = heightCalc+(heightCalc*0.075);
    var left = (pointX * width)/fullWidth;
    var height = (imgHeight*height)/fullHeight;
    var width = (imgWidth*width)/fullWidth;

    return {top:top, left:left, height:height, width:width};
  }

  clickProfile(event:any,pos:number){
    if(!pos){
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.changeText(pos);
  }

  changeText(pos:number){
    this.teamNameText = this.teamTextArr[pos].name;
    this.teamTitleText = this.teamTextArr[pos].title;
    this.teamBodyText = this.teamTextArr[pos].body;
    
  }

}
