import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import {RoomzQuery} from '../lib/+state/r2s.selector';
import * as RoomzActions from '../lib/+state/r2s.action';
import {RoomzState} from '../lib/+state/r2s.reducer';

@Injectable({ providedIn: 'root' })
export class MainFacade {
    error$ = this.store.select(RoomzQuery.getError);
    login$ = this.store.select(RoomzQuery.login);
    nfts$ = this.store.select(RoomzQuery.getNfts);
    style$ = this.store.select(RoomzQuery.getStyle);
    assets$ = this.store.select(RoomzQuery.getAssets);
    dimensions$ = this.store.select(RoomzQuery.getDimensions);
    moreNfts$ = this.store.select(RoomzQuery.getMoreNfts);
    remaining$ = this.store.select(RoomzQuery.getRemainingNfts);
    nftAssets$ = this.store.select(RoomzQuery.getNftAssets);
    signature$ = this.store.select(RoomzQuery.getSignature);
    mint$ = this.store.select(RoomzQuery.getMint);
    
    constructor(private store: Store<RoomzState>) {}

    login(id){
        this.store.dispatch(RoomzActions.getLogin({provider:id}));
    }

    getNfts(){
        this.store.dispatch(RoomzActions.getNfts({data:null}));
    }

    setStyle(style:string){
        this.store.dispatch(RoomzActions.changeStyle({data:style}));
    }

    getDimensions(names:any[]){
        this.store.dispatch(RoomzActions.getDimensions({data:names}));
    }

    logout(){
        this.store.dispatch(RoomzActions.getLogOut({data:null}));
    }

    loadNftsByContract(contract:any, pag:number, nfts:any[]){
        this.store.dispatch(RoomzActions.getNftsByContract({contract:contract, pagination:pag, nfts:nfts}));
    }

    mint(signature, quantity){
        this.store.dispatch(RoomzActions.mint({signature:signature, quantity:quantity}));
    }

    
}