import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { PagesModule } from "./pages/pages.module";
import { NavComponent } from './nav/nav.component';
import { environment } from "src/environments/environment.prod";
import { MainComponent } from "./pages/main/main.component";
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RoomzEffects } from "./lib/+state/r2s.effects";
import {RoomzFeatureKey, roomzInitialState, roomzReducer } from "./lib/+state/r2s.reducer";
import { MainFacade } from "./services/main.facade";
import { RoomzStore } from "./lib/r2s.store";
import { DragulaModule } from 'ng2-dragula';
//import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    MainComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    PagesModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    StoreModule.forRoot(
      {},
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(RoomzFeatureKey, roomzReducer, {
      initialState: roomzInitialState,
    }),
    EffectsModule.forFeature([RoomzEffects]),
    StoreDevtoolsModule.instrument(),
    DragulaModule.forRoot(),
    AngularToastifyModule

  ],
  providers: [RoomzStore, MainComponent, MainFacade, ToastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
