import { Component, OnInit, OnDestroy, TemplateRef, Inject, AfterViewInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DOCUMENT } from '@angular/common';
import { ApiService } from '../services/api.service';
import Moralis from 'moralis';
//import { environment } from '../../environments/environment.prod';
import { environment } from '../../environments/environment.dev';
import { Router } from '@angular/router';
import { MainComponent } from '../pages/main/main.component';
import { MainFacade } from '../services/main.facade';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Web3Service } from '../services/web3.services';

//import { MarketComponent } from '../pages/market/market.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})

export class NavComponent implements OnInit,OnDestroy {
  style$: Observable<any>|undefined;
  login$: Observable<any>|undefined;
  style:string="";
  private destroy$ = new Subject();
  isLoading: boolean;
  showMobileMenu: boolean = false;
  showLogin:boolean = false;
  wallet: any;
  loggedIn: boolean = false;
  profileDropDown: boolean = false;
  hasAccount: boolean = false;
  styleArray:any[]=["YACHT","SPACE"]; //,"CABIN"];
  cStyle:number = 0;
  loaded:boolean=false;
  constructor(@Inject(DOCUMENT) private doc: Document,
    private service: ApiService, private router: Router, private main:MainFacade, private web3:Web3Service) {

  }

  ngOnInit(): void {
    const btn = document.querySelector("button.mobile-menu-button");
    const dbtn = document.querySelector("button.mobile-menu-button-dark");
    const menu = document.querySelector(".mobile-menu");
    const menulink = document.querySelector(".menu-link");
    // Add Event Listeners
    btn?.addEventListener("click", ():void => {
      menu?.classList.toggle("hidden");
    });

    dbtn?.addEventListener("click", ():void => {
      console.log('here')
      menu?.classList.toggle("hidden");
    });

    document.addEventListener("click", (ele):void => {
      if(ele?.target && (ele.target as Element).className.indexOf('menu-link') >= 0){
        menu?.classList.toggle("hidden");
      }
      
    });
    this.setSubscription();
    
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  setSubscription(){
    this.login$ =this.main.login$;
    this.style$ = this.main.style$;
    this.style$ 
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.style = message;
      }

    });

    this.login$ 
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.main.getNfts();
      }

    });

    this.main.setStyle('YACHT');
  }

  updateStyle(){
    if(this.cStyle === (this.styleArray.length - 1)){
      this.cStyle = 0;
    }
    else{
      this.cStyle++;
    }
    if(this.styleArray[this.cStyle] === 'SPACE'){
      document.documentElement.classList.add('dark')
    }
    else {
      document.documentElement.classList.remove('dark')
    }
    this.main.setStyle(this.styleArray[this.cStyle]);
  }

  
  login(){
    //localStorage.setItem('wallet',provider);
    this.main.login(1);
    
  }
  
  logout(){
    this.main.logout();
  }


  private setLogOut() {
   

  }

  private setLoggedInUser(loggedInUser?: any) {
    
  }
}
