import { Injectable  } from '@angular/core';
import { Component } from '@angular/core';
import Moralis from 'moralis';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment.dev';
import { Store } from '@ngrx/store';
import * as RoomzActions from '../lib/+state/r2s.action';
import { RoomzState } from '../lib/+state/r2s.reducer';
import { NFTs } from '../entities/Nft';
import { HttpParams } from '@angular/common/http';
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import { ToastService } from 'angular-toastify';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { abi } from  "../contracts/prod/r2s.prod.js";
@Injectable({
  providedIn: 'root'
})
export class Web3Service {
  contractGroups:any[] = [];
  nftsCache:NFTs[] = [];
  nftCount:number = 0;
  provider:any;
  web3:any;
  web3Modal:any;
  wallet:any;
  wl=['0xd37ea75dd3c499eda76304f538cbf356ed9e7ed9', '0xB9951B43802dCF3ef5b14567cb17adF367ed1c0F','0xb668beb1fa440f6cf2da0399f8c28cab993bdd65','0x9b091d2e0bb88ace4fe8f0fab87b93d8ba932ec4','0x0cfb5d82be2b949e8fa73a656df91821e2ad99fd', '0x495f947276749Ce646f68AC8c248420045cb7b5e','0x916c6af08bf922eaf80c05975886c0a421c78a35']
  r2scontract="0x62906F76A36Ac0121440530187FEA428a30b220E";
  constructor(private store: Store<RoomzState>,private toast:ToastService) {}

  async initWeb3(){

    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "68bbfa6dd6594f328012419c5b654b2f" // required
        }
      }
    };
    
    this.web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    try {
      this.provider = await this.web3Modal.connect();
      this.web3  = new Web3(this.provider);
      let network = await this.web3.eth.net.getNetworkType();
   

      //Display warning if on the wrong network
      if(network !== 'main'){//'main'){ 1
        this.toast.info("Please switch to the Ethereum Mainnet network.");
        await this.web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex('1') }]
        });
        network = await this.web3.eth.net.getNetworkType();
      
        //return;
      }

      let accounts = await this.web3.eth.getAccounts();
      
      this.wallet = accounts[0];
      if(!localStorage.getItem('sig')){
        let message = 'This is used to verify that you own this account for Roomz to Show and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let sig = await this.web3.currentProvider.request({
          method: 'personal_sign',
          params: [msg, this.wallet, environment.token]
        });
   
        //console.log(sig)
        localStorage.setItem('sig', sig);
        //let rec = await this.web3.eth.accounts.recover(walletSet,sig);
        //console.log(rec)
      }
      else {
        let message = 'This is used to verify that you own this account for Roomz to Show and secures that any actions on the site are made by you alone.';
        let msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
        let rec = await this.web3.eth.accounts.recover(msg,localStorage.getItem('sig'));
        if(rec.toLowerCase() !== this.wallet.toLowerCase()){
          let sig = await this.web3.currentProvider.request({
            method: 'personal_sign',
            params: [msg,this.wallet, environment.token]
          });
          
          localStorage.setItem('sig', sig);
        }
      }
      
   
      return this.wallet;
    }
    catch(err){
      console.log(err)
      return {error:true,message:err.message};
    }
  }

  async logout(){
    try {
      this.web3Modal.clearCachedProvider();
      localStorage.removeItem('code');
      localStorage.removeItem('sig');
      return {};
    }
    catch(err){
      return {error:true,message:err.message};
    }
  }

  async getNFTsWallet(){
    try{
      let ethNFTs = [];
      //this.wallet = '0xcB266E2619729428BBf0dDd60FD8f1501e414608';
      let data = await this.getTokenMeta(this.wallet, null) as any;
      ethNFTs = data.ownedNfts;
      let count1 = 0;
      while(data.pageKey){
        data = await this.getTokenMeta(this.wallet, data.pageKey) as any;
        ethNFTs = ethNFTs.concat(data.ownedNfts);
        count1++;
      }

      let nfts=[];
      this.contractGroups = [];
      this.nftsCache = [];
      console.log(ethNFTs)
      //group by contract
      ethNFTs.forEach((nft)=>{
        if(nft.contract_address==='0xd37ea75dd3c499eda76304f538cbf356ed9e7ed9'){
          console.log(nft)
        }
        let foundContract = this.contractGroups.find((x)=>{
          return nft.contract.address === x.token_address;
        });
        if(foundContract){
          let foundToken = foundContract.token_ids.find((x)=>{
            return Web3.utils.hexToNumberString(nft.id.tokenId) == x;
          });
          if(!foundToken){
            foundContract.token_ids.push(Web3.utils.hexToNumberString(nft.id.tokenId));
          }
        }
        else{
          let newContract ={
            token_address:nft?.contract?.address,
            name:nft?.contractMetadata?.name ? nft?.contractMetadata?.name : nft?.metadata?.name,
            token_ids: []
          }
    
          newContract.token_ids.push(Web3.utils.hexToNumberString(nft.id.tokenId));
          this.contractGroups.push(newContract);
        }
      });
   
   
      let count = 0;
      this.nftCount = ethNFTs.length;
 
      for(let x = 0; ethNFTs.length > x; x++){
        if(count < 20){
          let img =ethNFTs[x].media[0].gateway;
          let imgMet = ethNFTs[x];
          let token = Web3.utils.hexToNumberString(ethNFTs[x].id.tokenId);

          if(this.wl.indexOf(ethNFTs[x].contract.address) > -1 ){
            img = await this.getOpenseaData(ethNFTs[x].contract.address,token);
          }
          else if(imgMet?.metadata?.image_data){
            img = imgMet?.metadata?.image_data;
            
          }
          else if(imgMet?.metadata?.image && imgMet?.metadata?.image.indexOf('ipfs://') >=0){
                let split = imgMet?.metadata?.image?.split("//");
                img = "https://0nyxlabs.mypinata.cloud/ipfs/" + split[1]
          }
          else{
                img = imgMet?.metadata?.image;
          }
          
          nfts.push({img:img,name:ethNFTs[x].contractMetadata?.name, token:token, token_address:ethNFTs[x].contract.address});
          this.nftsCache.push({img:img, token:token, name:ethNFTs[x].contractMetadata?.name, token_address:ethNFTs[x].contract.address} as NFTs)
          
          count++;
        }
      }

      return {nfts:nfts, moreNfts:nfts.length !== this.nftCount, remaining: this.nftCount - nfts.length };
    }
    catch(err){
      console.log(err)
      return {error:true,message:err.message};

    }
  }

  async mint(quantity,signature){
    try{
      
      /*let sig = await this.web3.eth.abi.encodeParameters(
        ['address', 'uint256'],
        [this.wallet, 1]
      );*/
    
      let contract = new this.web3.eth.Contract(abi, this.r2scontract);
      let unformatprice = await contract.methods.tokenData(1).call();
      let basePrice = unformatprice?.price;
      let gas = await contract.methods.mint(1,quantity, signature).estimateGas({ from: this.wallet, value:basePrice  });
    
      let limit = gas + 15000;
      let gasPrice = await this.web3.eth.getGasPrice();
      
     await contract.methods.mint(1,quantity, signature).send({ from: this.wallet, value:basePrice, gasLimit:limit, gasPrice:gasPrice  })
     .on("transactionHash", async (hash) => {
          console.log(hash)
          this.toast.success("Transaction has been started. Please wait for the transaction to be confirmed.");
        })
        .on("receipt", async(receipt) => {
          this.toast.success("Transaction has completed. Thank you for your purchase");
          //return {mint:receipt};
        })
        .on("error", async(error)=>{
          this.toast.error(error.message);
          return {error:error.message};
        });
      
      return {mint:'success'};
    }
    catch(err){
      console.log(err)
      this.toast.error("Error occured while mint. Please reach out via discord for assistance.");
      return {error:'Error occured while mint.'};
    }
  }

  async loadByContract(contract:string, pag:number, nftsOld:any[]){
    try{
      //console.log(contract)
      if(!contract){
        
        return {nfts:this.nftsCache,moreNfts:this.nftsCache.length !== this.nftCount, remaining: this.nftCount - this.nftsCache.length}
      }
    
      let foundContract = this.contractGroups.find((x)=>{
        return contract === x.token_address;
      });
   

      let count = 0;
      for(let x = 0; foundContract.token_ids.length > x; x++){
        
          let cacheCheck = this.nftsCache.find(tk => tk.token === foundContract.token_ids[x] && foundContract.token_address == tk.token_address);

          if(!cacheCheck){
            let imgMet = await this.getTokenMetaImage(foundContract.token_address, foundContract.token_ids[x]);
            let img = imgMet?.media[0]?.gateway;
       
            if(this.wl.indexOf(foundContract.token_address) > -1  ){
              //let tempimg = atob(imgMet?.metadata?.image_data.replace(/data:image\/svg\+xml;base64,/, ''));
              //console.log(tempimg)
              //tempimg = tempimg.replace('href', 'xlink:href');
              //console.log(tempimg)
              //var encodedData = window.btoa(tempimg);
              
              //img =  tempimg.split('href').join('xlink:href');
              //img =  img.split('<svg').join('<svg xmlns:xlink="http://www.w3.org/1999/xlink id="1"')
          
              img = await this.getOpenseaData(foundContract.token_address, foundContract.token_ids[x])
            
              
            }
            else if(imgMet?.metadata?.image_data){
              img = imgMet?.metadata?.image_data;
              
            }
            else if(imgMet?.metadata?.image && imgMet?.metadata?.image.indexOf('ipfs://') >=0){
                  let split = imgMet?.metadata?.image?.split("//");
                  img = "https://0nyxlabs.mypinata.cloud/ipfs/" + split[1]
            }
            else{
                  img = imgMet?.metadata?.image;
            }
         
            if(img){
         
              let nwObj = {img:img,token:foundContract.token_ids[x], name: foundContract.name,  token_address:foundContract.token_address} as NFTs;
              this.nftsCache = Object.assign([], this.nftsCache);
              this.nftsCache.push(nwObj )
             
              
            }
            count++;
          }
       
  
      }
     
      let nfts = this.nftsCache.filter((nft)=>{
        return nft.token_address === contract;
      });

     
      return {nfts:nfts, moreNfts:false, remaining: foundContract.token_ids.length - this.nftsCache.length };
    

    }
    catch(err){
      console.log(err)
      return err;
    }
  }

  async getImage(url:string){
    try {
      let response = await fetch(url);
      let data;
      let img = '';
      if(response) {
        data = await response.json();
        
        if(data){
          if(data.image.indexOf('ipfs') >=0){
            let split = data.image.split("//");
            let imgUrl = "https://0nyxlabs.mypinata.cloud/ipfs/" + split[1]
          }
          else{
            img = data.image;
          }

          
          
        }
      }
      return img;
    }
    catch(err){
      
      return null;
    }

  }

  async getOpenseaData(token_address, token_id){

    try{
      
      /*const res = await Moralis.Plugins.opensea.getAsset({
        network: "testnet",
        tokenAddress: token_address,
        tokenId: token_id,
      });
      console.log(res)*/
      let response = await fetch('https://api.opensea.io/api/v2/chain/ethereum/contract/'+token_address +'/nfts/'+token_id,{
        headers:{
          "x-api-key":"ada15aa88db841f3a595c2f02fccb265"
        }
      });
      let data;
      let img = '';
      if(response) {
        data = await response.json();
      
        if(data){
          img = data.nft.image_url;
        }
      }
      return img;
    }
    catch(err){
      console.log(err)
    }
  }

  async getTokenMeta(token_address, cursor){
    try{
   
      let url = 'https://eth-mainnet.g.alchemy.com/nft/v2/R-eAGMV4PIMk_nDpf_gDMZf8RN4hfv0e/getNFTs?owner='+token_address
     
      if(cursor){
        url += '&pageKey='+cursor;
      }
      url+='&withMetadata=true'
      let response = await fetch(url);
      let data = null;

      if(response) {
        data = await response.json();
        //console.log(data)
      }
      return data;
    }
    catch(err){
      console.log(err)
    }
  }

  async getTokenMetaImage(token_address, id){
    try{

      let url = 'https://eth-mainnet.g.alchemy.com/nft/v2/R-eAGMV4PIMk_nDpf_gDMZf8RN4hfv0e/getNFTMetadata?contractAddress='+token_address+'&tokenId='+id+'&refreshCache=false'

      let response = await fetch(url);
      let data = null;

      if(response) {
        data = await response.json();
       
      }
      return data;
    }
    catch(err){
      console.log(err.message)
    }
  }

  async getMoralisData(token_address, cursor){

    try{
      let url = 'https://eth-mainnet.alchemyapi.io/v2/'+token_address+'/nft';
      if(cursor){
        url += '?cursor='+cursor;
      }
      let response = await fetch(url, {
        headers: new Headers({'x-api-key': 'komhjNL5MNJS8cGgsLeBSTtQcQDSRShS9cAWwPIyLzno7t9vzdgH7rTqsUE8gJ8x'})
      });
      let data = null;

      if(response) {
        data = await response.json();
        console.log(data)
      }
      return data;
    }
    catch(err){
      console.log(err)
    }
  }

  searchCollections(input){
    let foundContract = this.contractGroups.filter((x)=>{
      let lowerName = x.name ? x.name.toString().toLowerCase() : '';
      return lowerName.indexOf(input.toLowerCase()) > -1;
    });

    return foundContract;
  }


}
/*
async loadByContract(contract:string, pag:number, nftsOld:any[]){
    try{
    if(!contract){

      if(pag > 0){
        let count = 0;
        let temp = [];
        //contract loop
        for(let x = 0; x < this.contractGroups.length; x++ ){
         
          for(let tk = 0; tk < this.contractGroups[x].token_ids.length; tk++){
      
            if(count < pag){

              //check if token is in cache
              let cacheCheck = this.nftsCache.find(tok =>{
                
                 return tok.token === this.contractGroups[x].token_ids[tk] && this.contractGroups[x].token_address == tok.token_address;
              });
             
              if(!cacheCheck){
        
                let imgMet = await this.getTokenMetaImage(this.contractGroups[x].token_address, this.contractGroups[x].token_ids[tk]);

                let img =imgMet?.media[0]?.gateway;
                if(this.wl.indexOf(this.contractGroups[x].token_address) > -1){
                  //let tempimg = atob(imgMet?.metadata?.image_data.replace(/data:image\/svg\+xml;base64,/, ''));
                  //console.log(tempimg)
                  //tempimg = tempimg.replace('href', 'xlink:href');
                  //console.log(tempimg)
                  //var encodedData = window.btoa(tempimg);
                  
                  //img =  tempimg.split('href').join('xlink:href');
                  //img =  img.split('<svg').join('<svg xmlns:xlink="http://www.w3.org/1999/xlink id="1"')
                  img = await this.getOpenseaData(this.contractGroups[x].token_address, this.contractGroups[x].token_ids[tk])
                  
                  
                }
                else if(imgMet?.metadata?.image_data){
                  img = imgMet?.metadata?.image_data;
                  
                }
                else if(imgMet?.metadata?.image && imgMet?.metadata?.image.indexOf('ipfs://') >=0){
                      let split = imgMet?.metadata?.image?.split("//");
                      img = "https://0nyxlabs.mypinata.cloud/ipfs/" + split[1]
                }
                else{
                      img = imgMet?.metadata?.image;
                }
              
                let nwObj = {img:img, name:this.contractGroups[x].name, token:this.contractGroups[x].token_ids[tk], token_address:this.contractGroups[x].token_address} as NFTs;
                this.nftsCache = Object.assign([], this.nftsCache);
                this.nftsCache.push(nwObj )
                count++;
              }
            }
            
          }
        }

        return {nfts:this.nftsCache, moreNfts:this.nftCount !== this.nftsCache.length, remaining: this.nftCount - this.nftsCache.length };
      }
      else{
        return {nfts:this.nftsCache, moreNfts:this.nftCount !== this.nftsCache.length, remaining: this.nftCount - this.nftsCache.length };
      }

    }
    else{
      let foundContract = this.contractGroups.find((x)=>{
        return contract === x.token_address;
      });


      let count = 0;
      for(let x = 0; foundContract.token_ids.length > x; x++){
        if(count < pag){
          let cacheCheck = this.nftsCache.find(tk => tk.token === foundContract.token_ids[x] && foundContract.token_address == tk.token_address);

          if(!cacheCheck){
            let imgMet = await this.getTokenMetaImage(foundContract.token_address, foundContract.token_ids[x]);
            let img = imgMet?.media[0]?.gateway;
       
            if(this.wl.indexOf(foundContract.token_address) > -1  ){
              //let tempimg = atob(imgMet?.metadata?.image_data.replace(/data:image\/svg\+xml;base64,/, ''));
              //console.log(tempimg)
              //tempimg = tempimg.replace('href', 'xlink:href');
              //console.log(tempimg)
              //var encodedData = window.btoa(tempimg);
              
              //img =  tempimg.split('href').join('xlink:href');
              //img =  img.split('<svg').join('<svg xmlns:xlink="http://www.w3.org/1999/xlink id="1"')

              img = await this.getOpenseaData(foundContract.token_address, foundContract.token_ids[x])
              
              
            }
            else if(imgMet?.metadata?.image_data){
              img = imgMet?.metadata?.image_data;
              
            }
            else if(imgMet?.metadata?.image && imgMet?.metadata?.image.indexOf('ipfs://') >=0){
                  let split = imgMet?.metadata?.image?.split("//");
                  img = "https://0nyxlabs.mypinata.cloud/ipfs/" + split[1]
            }
            else{
                  img = imgMet?.metadata?.image;
            }
            
            if(img){
              this.nftsCache.push({img:img,name: foundContract.name, token:foundContract.token_ids[x], token_address:foundContract.token_address} as NFTs);

              
            }
            count++;
          }
        }
        else {
          
          //return;
          x= foundContract.token_ids.length;
        }
  
      }

      let nfts = this.nftsCache.filter((nft)=>{
        return nft.token_address === contract;
      });

     
      return {nfts:nfts, moreNfts:foundContract.token_ids.length !== nfts.length, remaining: foundContract.token_ids.length - this.nftsCache.length };
    }

    }
    catch(err){
      console.log(err)
      return err;
    }
  } */