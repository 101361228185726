import { Component, OnInit, OnDestroy, AfterViewInit, TemplateRef, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Moralis from "moralis";
import { Observable, Subject } from 'rxjs';
import { MainFacade } from 'src/app/services/main.facade';
import { takeUntil } from 'rxjs/operators';
import { RoomzStore } from 'src/app/lib/r2s.store';
import { Router } from '@angular/router';



declare var myExtObject: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host:{
    '(document:mousemove)':'mouseMove($event)'

  }
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  style:string = '';
  itemSwitch:number;
  style$: Observable<any>|undefined;
  assets$: Observable<any>|undefined;
  private destroy$ = new Subject();
  cBG:number=0;
  cBG2:number=1;
  cTable:number=0;
  cLocation:number=0;
  cLocation2:number=1;
  cLeft:number=0;
  cRight:number=0;
  cFrame:number=0;
  cLounge:number=0;
  cDisplay:number=0;
  BG:any[]=[];
  locations:any[]=[];
  tables:any[]=[];
  display:any[]=[];
  left:any[]=[];
  right:any[]=[];
  lounge:any[]=[];
  frames:any[]=[];
  loaded:boolean = false;
  constructor(@Inject(DOCUMENT) document, private main:MainFacade, private store:RoomzStore, private router:Router) { }

  ngOnInit(): void {
    this.itemSwitch = 1;
    this.setSubs();
    this.updateStyle();
    
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  ngAfterViewInit(){
  }

  setSubs(){
    this.style$ = this.main.style$;
    this.assets$ = this.main.assets$;
    this.startTrans();
    this.style$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message && message !== this.style){
        this.style = message;
        this.loaded = false;
        this.updateStyle();
        setTimeout(()=>{
          this.loaded = true;
        },1000);

      }

    });

    this.assets$
    .pipe(takeUntil(this.destroy$))
    .subscribe((message)=>{ 
      if(message){
        this.BG = message.bg;
        this.locations = message.location;
        this.lounge = message.lounge;
        this.tables = message.tables;
        this.display = message.display;
        this.left = message.left;
        this.right = message.right;
        this.frames = message.frames;
      }

    });
    
  }

  mouseMove(e){

    if(window.innerHeight < 700 ){
      return false; //transY = '%) translateY(-50%)';
    }
    var valueX = e.pageX / window.innerHeight;

      var valueY = (e.pageY / window.innerWidth);
      var transY = '%) translateY(-50%)';

  
      document.getElementById('show').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('show').style.transform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('showFad').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('showFad').style.transform = 'translateX(' + valueX * -1 + transY;
      //document.getElementById('to').style.webkitTransform = 'translateX(' + valueX * 1 + '%) translateY(-50%)';
      //document.getElementById('to').style.transform = 'translateX(' + valueX * 1 + '%) translateY(-50%)';
      document.getElementById('roomz').style.webkitTransform = 'translateX(' + valueX * 1 + transY;
      document.getElementById('roomz').style.transform = 'translateX(' + valueX * 1 + transY;
      //document.getElementById('backg').style.webkitTransform = 'translateX(' + valueX * .1 + '%) translateY(-50%)';
      //document.getElementById('backg').style.transform = 'translateX(' + valueX * .1 + '%) translateY(-50%)';
      document.getElementById('lounge').style.webkitTransform = 'translateX(' + valueX * 1 + transY;
      document.getElementById('lounge').style.transform = 'translateX(' + valueX * 1 + transY;
      document.getElementById('table').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('table').style.transform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('right').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('right').style.transform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('left').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('left').style.transform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('display').style.webkitTransform = 'translateX(' + valueX * -1 + transY;
      document.getElementById('display').style.transform = 'translateX(' + valueX * -1 + transY;
  }

  updateStyle(){
    this.cBG=0;
    this.cBG2=1;
    this.cTable=0;
    this.cLocation=0;
    this.cLocation2=1;
    this.cLeft=0;
    this.cRight=0;
    this.cFrame=0;
    this.cLounge=0;
    this.cDisplay=0;
    this.itemSwitch = 1;
  }

  async startTrans(){
    try{
      if(window.innerHeight < 700 ){
        return false;
      }
      if(this.itemSwitch === 1){
        await this.spfadeOutEffect('backg');
        this.cBG = this.changeItem(this.cBG, this.store.getBG());
        await this.delay(1000);
        await this.fadeInEffect('backg');
        this.cBG2 = this.changeItem(this.cBG2, this.store.getBG());
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 2){
        /*await this.spfadeOutEffect('location');
        this.cLocation = this.changeItem(this.cLocation, this.store.getLocation());
        await this.delay(1000)
        await this.fadeInEffect('location');
        await this.spfadeOutEffect('location2');
        this.cLocation2 = this.changeItem(this.cLocation2, this.store.getLocation());*/
        document.getElementById('location2').style.opacity = '1';
        await this.spfadeOutEffect('location');
        this.cLocation = this.changeItem(this.cLocation, this.store.getLocation());
        await this.delay(1000);
        document.getElementById('location').style.opacity = '1';
        document.getElementById('location2').style.opacity = '0';
        this.cLocation2 = this.changeItem(this.cLocation2, this.store.getLocation());
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 3){
        await this.fadeOutEffect('display');
        this.cDisplay= this.changeItem(this.cDisplay, this.store.getDisplay());
        await this.delay(500);
        await this.fadeInEffect('display');
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 4){
        await this.fadeOutEffect('table');
        this.cTable= this.changeItem(this.cTable, this.store.getTable());
        await this.delay(500);
        await this.fadeInEffect('table');
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 5){
        await this.fadeOutEffect('left');
        this.cLeft = this.changeItem(this.cLeft, this.store.getLeft());
        await this.delay(500);
        await this.fadeInEffect('left');
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 6){
        await this.fadeOutEffect('right');
        this.cRight = this.changeItem(this.cRight, this.store.getRight());
        await this.delay(500);
        await this.fadeInEffect('right');
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 7){
        await this.fadeOutEffect('lounge');
        this.cLounge = this.changeItem(this.cLounge, this.store.getLounge());
        await this.delay(500);
        await this.fadeInEffect('lounge');
        this.itemSwitch++;
      }
      else if(this.itemSwitch === 8){
        await this.fadeOutEffect('frames');
        this.cFrame = this.changeItem(this.cFrame, this.store.getFrames());
        await this.delay(500);
        await this.fadeInEffect('frames');
        this.itemSwitch = 1;
      }
  
      await this.delay(6000);
  
      this.cycle();
    }
    catch(err){}
    
  }

  cycle(){

    if(this.router.url.indexOf('home') < 0) return;
    this.startTrans();
  }

  changeItem(position, assets){
    if(position + 1 >= assets.length ){
      return 0;
    }
    else {
      position++
      return position;
    }
  }

  
  async fadeOutEffect(id:string) { 
    try{
      let loop = true;
      var fadeTarget = document.getElementById(id);
      if(!fadeTarget) return;
      while(loop){
        if (!fadeTarget.style.opacity) {
          fadeTarget.style.opacity = '1';
        }
        if (Number(fadeTarget.style.opacity) > 0) {
          let numV = Number(fadeTarget.style.opacity);
          fadeTarget.style.opacity = (numV - 0.1).toString();
        } else {
            loop = false;
        }
        await this.delay(100)
      }
    }
    catch(err){

    }
    
    
  }

  async fadeInEffect(id:string) { 
    try{
      let loop = true;
      let increment = 0.1;
      
      var fadeTarget = document.getElementById(id);
      fadeTarget.style.opacity = '0.01';
      await this.delay(300)
      if(!fadeTarget) return;
      while(loop){
  
        if (Number(fadeTarget.style.opacity) < 1) {
          let numV = Number(fadeTarget.style.opacity);
          fadeTarget.style.opacity = (numV + increment).toString();
        } else {
            loop = false;
        }
        await this.delay(100)
      }
    }
    catch(err){

    }
    
    
  }

  async spfadeOutEffect(id:string) { 
    try{
      let loop = true;
      var fadeTarget = document.getElementById(id);
      if(!fadeTarget) return;
      while(loop){
  
          var fadeTargetLoc2 = document.getElementById('location2');
          if (!fadeTarget.style.opacity) {
            fadeTarget.style.opacity = '1';
          }
  
          if (Number(fadeTarget.style.opacity) > 0) {
            let numV = Number(fadeTarget.style.opacity);
            fadeTarget.style.opacity = (numV - 0.10).toString();
            if(id === 'location'){
              let numV2 = Number(fadeTargetLoc2.style.opacity);
              fadeTargetLoc2.style.opacity = (numV2 + 0.10).toString();
            }
            
          } else {
              loop = false;
          }
        
        await this.delay(100)
      }
    }
    catch(err){

    }
    
    
  }


  delay(t) {
    return new Promise(resolve => setTimeout(resolve, t))
  }



}
