import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  displayGunCash: boolean = true;
  displayGunCashText: boolean = false;
  displayRecord: boolean = true;
  displayRecordText: boolean = false;
  displayChest: boolean = true;
  displayChestText: boolean = false;

  hoveringOverRecord = false;
  hoveringOverGunMoney = false;
  hoveringOverChest = false;
  constructor() { }
  ngOnInit(): void {
    document.addEventListener('click',(src)=>{
      this.hideAll();
    })
  }

  calcDisplay(event, item) {
    if(!item){
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (item === 'gun_cash') {
      if (this.displayGunCashText) {
        this.displayGunCashText = false;
        this.displayAllItems();
      } else {
        this.displayGunCash = true;
        this.displayGunCashText = true;
        this.displayRecord = false;
        this.displayRecordText = false;
        this.displayChest = false;
        this.displayChestText = false;
      }
    } else if (item === 'record') {
      if (this.displayRecordText) {
        this.displayRecordText = false;
        this.displayAllItems();
      } else {
        this.displayRecord = true;
        this.displayRecordText = true;
        this.displayGunCash = false;
        this.displayGunCashText = false;
        this.displayChest = false;
        this.displayChestText = false;
      }
    } else if (item === 'chest') {
      if (this.displayChestText) {
        this.displayChestText = false;
        this.displayAllItems();
      } else {
        this.displayChest = true;
        this.displayChestText = true;
        this.displayGunCash = false;
        this.displayGunCashText = false;
        this.displayRecord = false;
        this.displayRecordText = false;
      }
    }
  }

  displayAllItems() {
    this.displayGunCash = true;
    this.displayRecord = true;
    this.displayChest = true;
  }

  hideAll(){
    this.displayGunCash = true;
    this.displayGunCashText = false;
    this.displayRecord = true;
    this.displayRecordText = false;
    this.displayChest = true;
    this.displayChestText = false;
  }
}
