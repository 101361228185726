import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as RoomzActions from './r2s.action';
import { Action } from 'rxjs/internal/scheduler/Action';
import { RoomzState } from './r2s.reducer';
import { ApiService } from '../../services/api.service';
import { Web3Service } from '../../services/web3.services';
import { RoomzStore } from '../r2s.store';
import { ToastService } from 'angular-toastify';



@Injectable()
export class RoomzEffects {

   constructor(
    private actions$: Actions,
    private store: Store<RoomzState>,
    private web3:Web3Service,
    private roomz:RoomzStore,
    private api:ApiService,
    private toast:ToastService
  ) {}

  changeStyle = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.changeStyle),
      map((action: any) => {
        this.roomz.setStyle(action.data);
        let data = {
            style:this.roomz.setStyle(action.data),
            assets:{
              bg:this.roomz.getBG(),
              location:this.roomz.getLocation(),
              tables:this.roomz.getTable(),
              left:this.roomz.getLeft(),
              right:this.roomz.getRight(),
              display:this.roomz.getDisplay(),
              lounge:this.roomz.getLounge(),
              frames:this.roomz.getFrames()
            }
        }
        
        return RoomzActions.changeStyleSuccess({style:data});
      }
    )
    )
  });


  getNFTs = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getNfts),
      concatMap((action: any) => from(this.web3.getNFTsWallet()).pipe(
        map((data: any) =>{
            if(data.error){
                return RoomzActions.getNftsFail({error:{message:"ERROR OCCURED ON WALLET CONNECT."}});
            }
            
            return RoomzActions.getNftsSuccess({Nfts: data.nfts, moreNfts:data.moreNfts, remaining:data.remaining});
        }),
        catchError((error) => {
          return of(RoomzActions.getNftsFail({error}));
        })

        )
      )
    );
    }
  );

  login = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getLogin),
      concatMap((action: any) => from(this.web3.initWeb3()).pipe(
        map((data: any) =>{
            if(data.error){
              return RoomzActions.getLoginFail({error:{message:"ERROR OCCURED ON AUTHENTICATION."}});
            }
            
            this.store.dispatch(RoomzActions.getOwnerAssets({wallet:data}));
            this.store.dispatch(RoomzActions.getSignature({wallet:data, id:action.provider}));
            return RoomzActions.getLoginSuccess({login: data});
        }),
        catchError((error) => {
          return of(RoomzActions.getLoginFail({error}));
        })

        )
      )
    );
    }
  );

  logout = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getLogOut),
      concatMap((action: any) => from(this.web3.logout()).pipe(
        map((data: any) =>{

            return RoomzActions.getLogOutSuccess({logout: data});
        }),
        catchError((error) => {
          return of(RoomzActions.getLogOutFail({error}));
        })

        )
      )
    );
    }
  );

  loadDimensions= createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getDimensions),
      concatMap((action: any) => this.api.getDimensions(action.data).pipe(
        map((data: any) => {
          //Group Dimensions by NFT maybe?
          return RoomzActions.getDimensionsSuccess({
            dimensions: data
          })
        }),
        catchError((error) => {
          return of(RoomzActions.getDimensionsFail({error}));
        })

        )
      )
    );
    }
  );

  ownersAsset= createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getOwnerAssets),
      concatMap((action: any) => this.api.getAssets(action.wallet).pipe(
        map((data: any) => {
          //Group Dimensions by NFT maybe?
          console.log(data)
          return RoomzActions.getOwnerAssetsSuccess({
            assets: data.assets
          })
        }),
        catchError((error) => {
          return of(RoomzActions.getOwnerAssetsFail({error}));
        })

        )
      )
    );
    }
  );

  getNFTsByContract = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getNftsByContract),
      concatMap((action: any) => from(this.web3.loadByContract(action.contract, action.pagination, action.nfts)).pipe(
        map((data: any) =>{
            if(data.error){
                return RoomzActions.getNftsByContractFail({error:{message:"ERROR OCCURED ON GETTING NFTs."}});
            }
            
            return RoomzActions.getNftsByContractSuccess({Nfts: data.nfts, moreNfts:data.moreNfts, remaining:data.remaining});
        }),
        catchError((error) => {
          return of(RoomzActions.getNftsByContractFail({error}));
        })

        )
      )
    );
    }
  );

  mint = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.mint),
      concatMap((action: any) => from(this.web3.mint(action.quantity, action.signature)).pipe(
        map((data: any) =>{
            if(data.error){
                return RoomzActions.mintFail({error:data.error});
            }
            
            return RoomzActions.mintSuccess({mint:data.mint});
        }),
        catchError((error) => {
          return of(RoomzActions.mintFail({error}));
        })

        )
      )
    );
    }
  );

  signature = createEffect(() => {
    return this.actions$.pipe(
      ofType(RoomzActions.getSignature),
      concatMap((action: any) => this.api.getSignature(action.wallet, action.id).pipe(
        map((data: any) =>{

            
            return RoomzActions.getSignatureSuccess({signature:data.signature});
        }),
        catchError((error) => {
          return of(RoomzActions.getSignatureFail({error}));
        })

        )
      )
    );
    }
  );

}