import { createAction, props } from '@ngrx/store';

//UNIVERSAL
export const getNfts = createAction(
    '[roomz] GET_NFTS',
    props<{ data:any }>()
    );
  
  export const getNftsSuccess = createAction(
      '[roomz] GET_NFTS_SUCCESS',
      props<{ Nfts: any, moreNfts: boolean, remaining:number }>()
    );
  
  export const getNftsFail = createAction(
      '[roomz] GET_NFTS_FAIL',
      props<{ error: any }>()
  );

  export const changeStyle = createAction(
    '[roomz] CHANGE_STYLE',
    props<{ data:any }>()
    );
  
  export const changeStyleSuccess = createAction(
      '[roomz] CHANGE_STYLE_SUCCESS',
      props<{ style: any }>()
    );

    export const getLogin = createAction('[roomz] GET_LOGIN',
    props<{provider: any }>()
  );
  
  export const getLoginFail = createAction('[roomz] GET_LOGIN_FAIL',
    props<{error: any }>()
  );
  
  export const getLoginSuccess = createAction('[roomz] GET_LOGIN_SUCCESS',
    props<{login: any }>()
  );
  
  export const getLogOut = createAction('[roomz] GET_LOGOUT',
    props<{data: any }>()
  );
  
  export const getLogOutSuccess = createAction('[roomz] GET_LOGOUT_SUCCESS',
    props<{logout: any }>()
  );
  
  export const getLogOutFail = createAction('[roomz] GET_LOGOUT_FAIL',
    props<{error: any }>()
  );

  export const getDimensions = createAction('[roomz] GET_DIMENSIONS',
    props<{data: any[] }>()
  );
  
  export const getDimensionsSuccess = createAction('[roomz] GET_DIMENSIONS_SUCCESS',
    props<{dimensions: any }>()
  );
  
  export const getDimensionsFail = createAction('[roomz] GET_DIMENSIONS_FAIL',
    props<{error: any }>()
  );

  export const mint = createAction('[roomz] MINT',
    props<{ signature:any[], quantity:any }>()
  );
  
  export const mintSuccess = createAction('[roomz] MINT_SUCCESS',
    props<{mint: any }>()
  );
  
  export const mintFail = createAction('[roomz] MINT_FAIL',
    props<{error: any }>()
  );

  export const getOwnerAssets = createAction('[roomz] GET_OWNER_ASSETS',
    props<{wallet:any }>()
  );
  
  export const getOwnerAssetsSuccess = createAction('[roomz] GET_OWNER_ASSETS_SUCCESS',
    props<{assets: any[] }>()
  );
  
  export const getOwnerAssetsFail = createAction('[roomz] GET_OWNER_ASSETS_FAIL',
    props<{error: any }>()
  );

  export const getSignature = createAction('[roomz] GET_SIG',
  props<{wallet:any, id:any }>()
);

export const getSignatureSuccess = createAction('[roomz] GET_SIG_SUCCESS',
  props<{signature: any[] }>()
);

export const getSignatureFail = createAction('[roomz] GET_SIG_FAIL',
  props<{error: any }>()
);

  export const getNftsByContract = createAction('[roomz] GET_NFTS_BY_CONTRACT',
    props<{contract:any, pagination:number, nfts:any[] }>()
  );
  
  export const getNftsByContractSuccess = createAction('[roomz] GET_NFTS_BY_CONTRACT_SUCCESS',
    props<{Nfts: any, moreNfts: boolean, remaining:number }>()
  );
  
  export const getNftsByContractFail = createAction('[roomz] GET_NFTS_BY_CONTRACT_FAIL',
    props<{error: any }>()
  );
  